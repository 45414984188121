/*! http://responsiveslides.com v1.54 by @viljamis */

.rslides {
  position: relative;
  list-style: none;
  overflow: hidden;
  width: 593px;
  padding: 0;
  margin: 0 0 260px 38px;
  }

.rslides li {
  -webkit-backface-visibility: hidden;
  position: absolute;
  display: none;
  width: 100%;
  left: 0;
  top: 0;
  }

.rslides li:first-child {
  position: relative;
  display: block;
  float: left;
  }

.rslides img {
  display: block;
  height: auto;
  float: left;
  width: 100%;
  border: 0;
  }
