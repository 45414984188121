$px10:63%;
$px11:69%;
$px12:75%;
$px13:82%;
$px14:88%;
$px15:94%;
$px16:100%;
$px17:107%;
$px18:113%;
$px19:119%;
$px20:125%;
$px21:132%;
$px22:138%;
$px23:144%;
$px24:150%;
$px25:157%;
$px26:163%;
$px27:169%;
$px28:175%;
$px29:182%;
$px30:188%;
$px31:194%;
$px32:200%;
$px33:207%;
$px34:213%;
$px35:219%;
$px36:225%;
$px37:232%;
$px38:238%;
$px39:244%;
$px40:250%;
$px41:257%;
$px42:263%;
$px43:269%;
$px44:275%;
$px45:282%;
$px46:288%;
$px47:294%;
$px48:300%;
$px49:307%;
$px50:313%;
$px51:319%;
$px52:325%;
$px53:332%;
$px54:338%;
$px55:344%;
$px56:350%;
$px57:357%;
$px58:363%;
$px59:369%;
$px60:375%;
$px61:382%;
$px62:388%;
$px63:394%;
$px64:400%;
$px65:407%;
$px66:413%;
$px67:419%;
$px68:425%;
$px69:432%;
$px70:438%;
$px71:444%;
$px72:450%;