@charset "UTF-8";

@import "ptp-16";
@import "build";

//----初期設定------------------------------------------------------------------------------------------------------
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font: inherit;
	font-size: 100%;
	vertical-align: baseline;
}

html {
	line-height: 1;
}

ol, ul {
	list-style: none;
}

table {
	border-collapse: collapse;
	border-spacing: 0;
}

caption, th, td {
	text-align: left;
	font-weight: normal;
	vertical-align: middle;
}

q, blockquote {
	quotes: none;
}
q:before, q:after, blockquote:before, blockquote:after {
	content: "";
	content: none;
}

a img {
	border: none;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section, summary {
	display: block;
}

a {
	text-decoration:none;
	color:#1e8dc5;

	&:hover {
		text-decoration:none;
		opacity:1;
	}
}

img {
	vertical-align:bottom;
}

//----sass------------------------------------------------------------------------------------------------------
* {
	/*@include box-sizing(border-box);*/
	box-sizing:border-box;
}

.cf {
	/*@include clearfix;*/
	overflow:hidden;
	*zoom: 1;
}

$max_width :980px;
$main_color:#1e8dc5;
%cf {
	overflow: hidden;
	*zoom: 1;
}

%hidetext {
	display:block;
	text-indent:100%;
	white-space:nowrap;
	overflow:hidden;
}

//----クラス------------------------------------------------------------------------------------------------------
.inner {
	width:$max_width;
	margin:0 auto;
}

.dspn {
	display:none;
}

.block {
	margin:0 0 5em 0;

	p {
		text-indent:0em;
		line-height:1.75;
		margin:0 0 1.75em 0;
	}
}

.movie-contents {
	margin: 0 0 3em 0;
}

.movie {
	width: 100%;
	height: 0;
	position: relative;
	padding-top: 56.25%;
	overflow: hidden;
	margin: 0 0 2em 0;

	iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100% !important;
		height: 100% !important;
	}
}

.name {
	text-align:right;
	padding:0 0 2em 0;
	border-bottom:2px dotted #ccc;
}

//----書式------------------------------------------------------------------------------------------------------


//----body------------------------------------------------------------------------------------------------------
body {
	color:#333;
	font-family: "メイリオ", "ＭＳ Ｐゴシック", sans-serif;
	line-height:1.7;
}

//----header------------------------------------------------------------------------------------------------------
body#home header#pageHead {
	background:#74bee2 url(../images/bg_header.gif) left top repeat-x;
	padding:0 0 40px 0;
}

body#sub header#pageHead {
	background:url(../images/bg_header_sub.gif) left top repeat-x;
	padding:0 0 73px 0;
}

header#pageHead {
	width:100%;

	#headWrap {
		background:url(../images/bg_hero.jpg) center top no-repeat;
	}

	#head {
		width:1160px;
		margin:0 auto;
		padding:38px 0 0 0;
		position:relative;

		h1#logo,
		p#logo {
			width:331px;
			height: 39px;
			margin:0 0 73px 0;
		}

		p#logo {
			margin:0 0 0 0;
		}

		nav#gNav {
			position:absolute;
			top:38px;
			right: 0;

			li {
				display: inline-block;
				font-size: 16px;
				margin:0 10px 0 0;
					text-align: center;

				&:last-child {
					margin:0;
				}

				a {
					color: #396880;
					display:block;

					&:hover {
						color: #fff;

						span {
							border-top: 2px solid #fff;
						}
					}

					&.active {
						color: #fff;

						span {
							border-top: 2px solid #fff;
						}
					}
				}

				span {
					border-top: 2px solid #396880;
					display: block;
					font-size: 12px;
					padding: 5px 25px 10px 25px;
				}
			}
		}
	}
}

// .nav-sprite,
// .nav-about,
// .nav-bbs,
// .nav-contact,
// .nav-download,
// .nav-home,
// .nav-minutes {
// 	background-image: url('../images/nav.png');
// 	background-repeat: no-repeat
// }
// .nav-about {
// 	background-position: 0 0;
// 	height: 50px;
// 	width: 101px
// }
// .nav-about:hover {
// 	background-position: 0 -100px
// }
// .nav-about.about-active {
// 	background-position: 0 -50px
// }
// .nav-contact {
// 	background-position: 0 -300px;
// 	height: 50px;
// 	width: 113px
// }
// .nav-contact:hover {
// 	background-position: 0 -400px
// }
// .nav-contact.contact-active {
// 	background-position: 0 -350px
// }
// .nav-download {
// 	background-position: 0 -450px;
// 	height: 50px;
// 	width: 150px
// }
// .nav-download:hover {
// 	background-position: 0 -550px
// }
// .nav-download.download-active {
// 	background-position: 0 -500px
// }
// .nav-home {
// 	background-position: 0 -600px;
// 	height: 50px;
// 	width: 68px
// }
// .nav-home:hover {
// 	background-position: 0 -700px
// }
// .nav-home.home-active {
// 	background-position: 0 -650px
// }
// .nav-minutes {
// 	background-position: 0 -750px;
// 	height: 50px;
// 	width: 118px
// }
// .nav-minutes:hover {
// 	background-position: 0 -850px
// }
// .nav-minutes.minutes-active {
// 	background-position: 0 -800px
// }
// .nav-notice {
// 	background-position: 0 -900px;
// 	height: 50px;
// 	width: 85px
// }
// .nav-notice:hover {
// 	background-position: 0 -1000px
// }
// .nav-notice.notice-active {
// 	background-position: 0 -950px
// }

// $nav-sprite-dimensions: true;
// @import "nav/*.png";
// @include all-nav-sprites;

#hero {
	
	h2 {
		margin:0 0 270px 38px;
	}
}

#news {
	
	h2 {
		font-size:$px20;
	}
	
	dl {
		
		dt {
			position:absolute;
			
			.postTimeHome {
				margin:0 30px 0 0;
			}
		}
		
		dd {
			border-bottom:1px dotted #ccc;
			margin:0 0 13px 0;
			padding:0 0 13px 7em;
			
			&:last-child {
				margin:0 0 0 0;
			}
		}
	}
}

#home #news {
	
	h2 {
		color:$main_color;
	}
	
	dl {
		
		dt {
			color:#fff;
		}
		
		dd {
			border-bottom:1px dotted #fff;
			color:#fff;
			padding:0 0 13px 15em;
			
			&:last-child {
				border:none;
				margin:0 0 0 0;
				padding:0 0 0 15em;
			}
				
			a {
				color:#fff;
			}
		}
	}
}

body#home #main {
	background:#fdfdfd;
	
	#mainInner {
		width:$max_width;
		margin:0 auto;
		padding:60px 0;
	}
	
	h2 {
		font-size:$px20;
		text-align:center;
		margin:0 0 .5em 0;
	}
}

body#sub #main {
	
	#mainInner {
		width:$max_width;
		margin:0 auto;
		background:#fff;
		padding:60px 0;
	}
	
	h1 {
		color:#1e8dc5;
		font-size:$px32;
		margin:0 0 40px 0;
		padding:0 0 9px 33px;
		position:relative;
		border-bottom:3px solid #94c8e2;
		
		&:before {
			content:'';
			display:block;
			width:11px;
			height:1.2em;
			background:#94c8e2;
			position:absolute;
			top:.3em;
			left:0;
		}
	}
	
	ul#jigyosyoList {
		@extend %cf;
		
		li {
				width:300px;
				float:left;
				font-size:$px14;
				line-height:1.5;
				margin:0 40px 40px 0;
				
				&:nth-child(3n) {
					margin:0 0 40px 0;
				}
				
				img {
					margin:0 0 .5em 0;
				}
				
				h3 {
					color:#1e8dc5;
					font-size:$px16;
					margin:0 0 .2em 0;
				}
		}
	}
	
	h2 {
		font-size:$px28;
		margin:0 0 1em 0;
	}
	
	dl.docblock {
		background:#fdfdfd;
		margin:0 0 2px 0;
		padding:30px;
		border:1px solid #d2d2d2;
		
		dt {
			width:30%;
			float:left;
		}
		
		dd {
			width:70%;
			float:right;
			border-bottom:1px dashed #ccc;
			margin:0 0 10px 0;
			padding:0 0 10px 0;
		}
	}
	
	ul.doclist {
		
		li {
			border-bottom:1px dashed #ccc;
			margin:0 0 10px 0;
			padding:0 0 10px 0;
		}
	}
	
	ul.doclist2 {
		
		li {
			border-bottom:1px dashed #ccc;
			display:inline-block;
			width:30%;
			margin:0 0 10px 4%;
			padding:0 0 10px 0;
			
			&:nth-child(3n+1){
				margin:0 0 10px 0;
			}
		}
	}
}

#calendar {
	
	#youbi {
		display: flex;
		flex-wrap: wrap;
		border-left:1px solid #d2d2d2;
		
		li {
			background: #8d8d8d;
			border-top:1px solid #d2d2d2;
			border-right:1px solid #d2d2d2;
			color: #fff;
			width: calc(100% / 7);
			width: calc((100% - 1px) / 7)\9;
			font-size:$px12;
			font-weight:700;
			text-align:center;
			padding:4px 0;
		}
	}
	
	#day {
		display: flex;
		flex-wrap: wrap;
		overflow-y: hidden;
		overflow-x: hidden;
		width: 100%;
		height: 482px;
		position: relative;
		border-left:1px solid #d2d2d2;
		
		li {
			background: #f7f7f7;
			border-top:1px solid #d2d2d2;
			border-right:1px solid #d2d2d2;
			width: calc(100% / 7);
			width: calc((100% - 1px) / 7)\9;
			height: 80px;
			line-height:1.25;
			
			
		}
		
		span {
				background: #cbcbcb;
				color: #fff;
				font-size: $px12;
				font-weight:700;
				display: block;
				text-align: left;
				padding: 2px 9px;
		}
		
		a{
			font-size:$px12;
		}
	}
}

#bnr {
	background:#94c8e2;
	padding:16px 0;
	
	ul {
		display:table;
		padding:0 0 0 87px;
		width:100%;
		
		li {
			display:table-cell;
			font-size:$px18;
			vertical-align:middle;
			width:50%;
			
			&:first-child a {
				background:url(../images/list_icon_01.png) left center no-repeat;
				color:#fff;
				padding:0 0 0 15px;
			}
		}
	}
}

#foot {
	padding:45px 0 35px 0;
	position:relative;
	
	address {
		color:#555555;
		font-size:$px14;
		line-height:1.5;
		
		img {
			margin:0 0 10px 0;
		}
	}
	
	.cpd {
		@extend %cf;
		position:absolute;
		right:0;
		bottom:35px;
		line-height:1;
		
		span {
			display:inline-block;
		}
		
		.ctTxt {
			padding:5px 1px 3px 5px;
		}
		
		.cpd-r {
			font-size:$px14;
			
			span:nth-child(1),
			span:nth-child(2),
			span:nth-child(3),
			span:nth-child(4),
			span:nth-child(5),
			span:nth-child(6) {
				color:#fff;
				background:#555;
				margin:0 1px 0 0;
				padding:7px 7px 5px 7px;
			}
		}
	}
}

//----テーブル------------------------------------------------------------------------------------------------------
table {
	width:100%;
	margin:0 0 1.5em 0;
	
	th {
		text-align:left;
		vertical-align:middle;
		font-weight:normal;
		white-space:nowrap;
		padding:24px 30px;
	}
	
	td {
		text-align:left;
		vertical-align:middle;
		padding:24px 30px;
	}
}

.form {
	border-top:1px solid #e1e1e1;
	border-left:1px solid #e1e1e1;
	font-size:$px18;
	
	th {
		border-bottom:1px solid #e1e1e1;
		width:30%;
	}
	
	td {
		border-right:1px solid #e1e1e1;
		border-bottom:1px solid #e1e1e1;
	}
}

.meibo {
	border-top:1px solid #e1e1e1;
	border-left:1px solid #e1e1e1;
	
	thead th {
		background:#94c8e2;
		border-right:1px solid #e1e1e1;
		border-bottom:1px solid #e1e1e1;
		color:#fff;
	}
	
	tbody tr:nth-of-type(2n) {
		background:#f5f5f5;
	}
	
	tbody th {
		border-right:1px solid #e1e1e1;
		border-bottom:1px solid #e1e1e1;
	}
	
	tbody td {
		border-right:1px solid #e1e1e1;
		border-bottom:1px solid #e1e1e1;
	}
}

input[type=text],
input[type=email],
input[type=tel] {
	padding:13px;
	width:70%;
	border:1px solid #e1e1e1;
	border-radius:5px;
}

input[type=submit] {
	padding:13px;
	border:1px solid #e1e1e1;
	border-radius:5px;
}

input[type=radio] {
	position:relative;
	top:-2px;
}

label {
	margin:0 2em 0 0;
}

textarea {
	padding:13px;
	width:70%;
	border:1px solid #e1e1e1;
	border-radius:5px;
}

.required {
	font-size:$px10;
	color:#fff;
	background:#f44522;
	margin:0 10px 0 0;
	padding:3px;
	position:relative;
	top:-2px;
}

.wpcf7c-conf {
	background:rgb(255, 255, 204) !important;
	border:1px solid #e1e1e1 !important;
}

.wpcf7-confirm {
	padding:15px;
	width:35%;
	margin:0 auto;
	color:#fff;
	background:#94c8e2;
}

.wpcf7-submit {
	padding:15px;
	width:35%;
	margin:0 auto;
	color:#fff;
	background:#1e8dc5;
}

.btnFrom {
	font-size:$px18;
	text-align:center;
	margin:2em 0;
}

//----掲示板------------------------------------------------------------------------------------------------------
/*SimplicityでbbPressのスタイルを整える*/
.bbp-breadcrumb-home,
.bbp-breadcrumb-home+.bbp-breadcrumb-sep,
.topic .post-date,
.topic .post-update,
.topic .footer-post-meta,
.post-0 .post-date,
.post-0 .post-update,
.post-0 .footer-post-meta,
.forum .post-date,
.forum .post-update,
.forum .footer-post-meta,
.bbp-template-notice{
	display: none;
}
 
#bbp_search{
	font-size: $px18;
	height: 35px;
		padding:5px;
}

.bbp-search-form {
	margin:0 0 20px 0;
}
 
.post-0 .post-meta{
	height: 27px;
}
 
.bbp-reply-content{
	font-size: 16px;
	line-height: 170%;
}
 
.bbp-forum-title{
	font-size: 16px !important;
}
 
.bbp-topic-permalink{
	font-size: 14px !important;
}
 
#bbpress-forums .status-closed,
#bbpress-forums .status-closed a {
	color: #555 !important;
}

.bbp-breadcrumb {
	display:none;
}

#bbpress-forums ul.bbp-forums,
#bbpress-forums .bbp-forum-info .bbp-forum-content {
	font-size:$px18 !important;
}

.bbp-author-name {
	font-size:$px22 !important;
}

#bbpress-forums p.bbp-topic-meta img.avatar {
	width:35px;
	height:auto !important;
}

.button {
	background:#f7f7f7 !important;
	color:#888;
	font-weight:normal;
	padding: 0.7rem 2rem;
	margin-bottom: 0.5rem;
	border: none;
	font-size: 1.2rem;
	text-transform: uppercase;
	-webkit-border-radius: 6px;
	-moz-border-radius: 6px;
	-ms-border-radius: 6px;
	-o-border-radius: 6px;
	border-radius: 6px;
	vertical-align: middle;
	white-space: nowrap;
}

//----イベントカレンダー------------------------------------------------------------------------------------------------------
.tribe-events-meta-group {
	dl {
		
		dt {
			position:absolute;
		}
		
		dd {
			padding:0 0 0 3.5em;
		}
	}
}